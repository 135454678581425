import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useState } from 'react';

const Steps = ({ data }) => {

    // Перевод
    const { t, i18n } = useTranslation();

    // Показ разных версий блока в зависимости от размеров экрана
    const [mobile, setMobile] = useState(false);
    useEffect(() => {
        if (window.innerWidth < 1200) {
            setMobile(true);
        }
    }, [window.innerWidth])


    // Получение и вывод недель на пк версии
    const getWeeksText = week => {

        let content = [];

        for (let index = 1; index <= week; index++) {
            const item = index;

            if (i18n.language == 'ru') {
                content.push(<div key={item}>{item + ' ' + t("steps.weeks")}</div>);
            }

            if (i18n.language == 'en') {
                content.push(<div className='en' key={item}>{item + ' ' + t("steps.weeks")}</div>);
            }

        }

        return content;
    };

    return (
        !mobile ? (
            <div className={'fl-steps fl-steps__' + data.weeks}>
                <div className='fl-steps__headers'>
                    <div className='fl-steps__title'>{t("steps.steps")}</div>
                    {data.steps.map((step, i) => (
                        <div className='fl-steps__item'>{(i + 1) + ". " + step.title}</div>
                    ))}
                </div>
                <div className='fl-steps__body'>
                    <div className='fl-steps__body-top'>
                        {getWeeksText(data.weeks)}
                    </div>
                    <div className='fl-steps__body-bottom'>

                        {data.steps.map((step, i) => (
                            <div className={"fl-steps__body-item " + step.position + " " + step.length}>
                                <div>
                                    <div>
                                        <img src={step.icon} alt="" />
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        ) : (
            <div className={'fl-steps-mobile fl-steps__' + data.weeks}>
                {data.steps.map((step, index) => (
                    <div className="fl-steps-mobile__block">
                        <div className="fl-steps-mobile__deadline">{step.mobile + " " + t("steps.weeks")}</div>
                        <div className="fl-steps-mobile__task">{step.title}</div>
                        <div className={"fl-steps__body-item " + step.length}>
                            <div>
                                <div>
                                    <img src={step.icon} alt="" />
                                </div>
                                <div>{step.deadline}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )

    )
}
export default Steps;