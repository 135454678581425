import React from 'react';
import { useTranslation } from 'react-i18next';

// Компоненты основные
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree.jsx';

// Элементы react
import Typed from 'react-typed';
import { Link } from "react-router-dom";

// Компоненты страницы
import BrandTwo from '../elements/brand/BrandTwo';
import ServiceOne from "../elements/service/ServiceOne";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import PortfolioEn from "../elements/portfolio/PortfolioEn";
import AboutTwo from "../elements/about/AboutTwo";
import ContactForm from "../elements/contact/ContactForm.jsx";
import FormSale from '../elements/contact/FormSale';
import Contact from '../elements/contact/Contact';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TabTwo from '../elements/tab/TabTwo';
import FormAudit from '../elements/contact/FormAudit';

// Иконки
import { FiArrowRight } from "react-icons/fi";

const Marketing = () => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <SEO title="FLAMINGO" description="test" />
            <main className="page-wrapper">

                {/* Шапка */}
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Оффер  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">Digital Agency <br />  For {" "}
                                        <Typed
                                            strings={[
                                                "Development",
                                                "Marketing",
                                                "Analytics",
                                            ]}
                                            typeSpeed={80}
                                            backSpeed={5}
                                            backDelay={1000}
                                            loop
                                        />
                                    </h1>
                                    <p className="description">{t("offer.title")}
                                        <br></br>{t("offer.subtitle")}</p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to={process.env.PUBLIC_URL + "/#cases"}>{t("offer.buttonOne")} <i className="icon"><FiArrowRight /></i></Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to={process.env.PUBLIC_URL + "/#contacts"}>{t("offer.buttonTwo")} <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/bg/back_02.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />


                {/* Блок услуги  */}
                <div className="rn-service-area rn-section-gap" id="services">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Services"
                                    title={t("services.title")}
                                    description={t("services.subtitle")}
                                />
                            </div>
                        </div>

                        {/* Услуги */}
                        <ServiceOne
                            serviceStyle="service__style--1 bg-color-blackest radius mt--20 rbt-border"
                            textAlign="text-start"
                        />

                        {/* Форма "Скидка на разработку" */}
                        <FormSale />

                    </div>
                </div>


                <Separator />

                {/* Блок этапы работ  */}
                <div className="rwt-tab-area rn-section-gap">
                    <div className="container">
                        <div className="row justify-content-center mb--40">
                            <div className="col-lg-6">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Project Lifecycle"
                                    title={t("steps.title")}
                                    description={t("steps.subtitle")}
                                />
                            </div>
                        </div>
                        {/* Этапы */}
                        <TabTwo />
                    </div>
                </div>

                <Separator />

                {/* Блок портфолио  */}
                <div className="rwt-portfolio-area rn-section-gap" id="cases">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Business Projects"
                                    title={t("cases.title")}
                                    description={t("cases.subtitle")}
                                />
                            </div>
                        </div>
                        {i18n.language === "ru" &&
                            <PortfolioOne Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
                        }
                        {i18n.language === "en" &&
                            <PortfolioEn Column="col-lg-4 col-md-6 col-12 mt--30 portfolio" />
                        }

                    </div>
                </div>

                <Separator />

                {/* Блок с формой "Аудит"  */}
                <div className="rn-service-area rn-section-gapBottom">
                    <div className="container">
                        <FormAudit />
                    </div>
                </div>

                <Separator />

                {/* Блок о компании  */}
                <div className="rwt-about-area rn-section-gap" id="about">
                    <div className="container">
                        <div className="row justify-content-center mb--40">
                            <div className="col-lg-6">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="About us"
                                    title={t("about.title")}
                                    description={t("about.subtitle")}
                                />
                            </div>
                        </div>
                        {/* О окмпании */}
                        <AboutTwo />
                    </div>
                </div>

                <Separator />

                {/* Блок Наши клиенты  */}
                <div className="rwt-brand-area rn-section-gap" id="clients">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Awesome Clients"
                                    title={t("clients.title")}
                                    description={t("clients.subtitle")}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt--10">
                                {/* Клиенты */}
                                <BrandTwo brandStyle="brand-style-2" />
                            </div>
                        </div>
                    </div>
                </div>

                <Separator />

                {/* Блок контактов */}
                <div className="contact-area rn-section-gap" id="contacts">
                    <div className="container">
                        <div className="row row--30 mt--40">
                            <div className="col-xl-6 order-2 oder-lg-1 mt_md--30 mt_sm--30 mt_lg--30 col-12">

                                {/* Фоорма связи */}
                                <ContactForm formStyle="contact-form-1 bg-color-blackest radius" />

                            </div>
                            <div className="col-xl-6 order-1 oder-lg-2 col-12">

                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Contacts"
                                    title={t("contacts.title")}
                                    description={t("contacts.subtitle")}
                                />

                                {/* конакты */}
                                <Contact />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Футер */}
                <FooterThree />

            </main>
        </>
    )
}

export default Marketing;
