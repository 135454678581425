import React from 'react';
import { useTranslation } from 'react-i18next';

// Компоненты основные
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree.jsx';

const Policy = () => {

    const { t, i18n } = useTranslation();

    return (
        <>
            <SEO title="Политика конфиденциальности || FLAMINGO" />
            <main className="page-wrapper">

                {/* Шапка */}
                <HeaderTopBar />
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />

                {/* Блок контактов */}
                <div className="policy-area rn-section-gap" >
                    <div className="container">
                        <div className="row row--30 mt--40">

                            {i18n.language === "ru" &&
                                <div className="col-12">
                                    <h2>Политика конфиденциальности</h2>
                                    <p>Я, субъект персональных данных, в соответствии с Федеральным законом от 27 июля 2006 года № 152 «О персональных данных» предоставляю ИП Наумов Д.А. (далее - Оператор), расположенному по адресу Россия, г. Батайск, ул. Добролюбова, 24а, согласие на обработку персональных данных, указанных мной в форме веб-чата и/или в форме заказа обратного звонка на сайте leadback.ru в сети «Интернет», владельцем которого является Оператор.</p>
                                    <p>1.Состав предоставляемых мной персональных данных является следующим: ФИО, адрес электронной почты и номер телефона.</p>
                                    <p>2. Целями обработки моих персональных данных являются: обеспечение обмена короткими текстовыми сообщениями в режиме онлайн-диалога и обеспечение функционирования обратного звонка.</p>
                                    <p>3. Согласие предоставляется на совершение следующих действий (операций) с указанными в настоящем согласии персональными данными: сбор, систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, передачу (предоставление, доступ), блокирование, удаление, уничтожение, осуществляемых как с использованием средств автоматизации (автоматизированная обработка), так и без использования таких средств (неавтоматизированная обработка).</p>
                                    <p>4. Я понимаю и соглашаюсь с тем, что предоставление Оператору какой-либо информации о себе, не являющейся контактной и не относящейся к целям настоящего согласия, а равно предоставление информации, относящейся к государственной, банковской и/или коммерческой тайне, информации о расовой и/или национальной принадлежности, политических взглядах, религиозных или философских убеждениях, состоянии здоровья, интимной жизни запрещено.</p>
                                    <p>5. В случае принятия мной решения о предоставлении Оператору какой-либо информации (каких-либо данных), я обязуюсь предоставлять исключительно достоверную и актуальную информацию и не вправе вводить Оператора в заблуждение в отношении своей личности, сообщать ложную или недостоверную информацию о себе.</p>
                                    <p>6. Я понимаю и соглашаюсь с тем, что Оператор не проверяет достоверность персональных данных, предоставляемых мной, и не имеет возможности оценивать мою дееспособность и исходит из того, что я предоставляю достоверные персональные данные и поддерживаю такие данные в актуальном состоянии.</p>
                                    <p>7. Согласие действует по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.</p>
                                    <p>8. Согласие может быть отозвано мною в любое время на основании моего письменного заявления.</p>
                                </div>
                            }
                            {i18n.language === "en" &&
                                <div className="col-12">
                                    <h2>Privacy Policy</h2>
                                    <p>I, the subject of personal data, in accordance with the Federal Law of July 27, 2006 № 152 "On Personal Data" I provide IE Naumov D.A. ( further - Operator), located at the address of Russia, Bataysk, Dobrolyubova Street, 24a, consent to the processing of personal data indicated by me in the form of web chat and/or in the form of ordering a callback on the site leadback.ru on the Internet, the owner of which is the Operator.</p>
                                    <p>1.The composition of the personal data provided by me in is as follows: Full name, e-mail address and telephone number. </p>
                                    <p>2. The purposes of processing my personal data are: to provide the exchange of short text messages in online dialog mode and to ensure the functioning of the callback. </p>
                                    <p>3. Consent is granted for the following actions (operations) with the personal data specified in this consent: collection, systematization, accumulation, storage, clarification (update, change), use, transfer (provision, access), blocking, deletion, destruction, carried out both with the use of means of automation (automated processing) and without the use of such means (non-automated processing).</p>
                                    <p>4. I understand and agree that providing the Operator with any information about myself that is not contact information and not related to the purposes of this consent, as well as providing information related to state, banking and/or commercial secrets, information about race and/or nationality, political views, religious or philosophical beliefs, state of health, intimate life is prohibited. </p>
                                    <p>5. In case I decide to provide the Operator with any information (any data), I undertake to provide only reliable and up-to-date information and I have no right to mislead the Operator about my identity, to provide false or unreliable information about myself. </p>
                                    <p>6. I understand and agree that the Operator does not check the reliability of the personal data provided by me and has no opportunity to assess my capacity and assumes that I provide reliable personal data and keep such data up to date. </p>
                                    <p>7. Consent shall be valid when the purposes of processing have been achieved or when it is no longer necessary to achieve these purposes, unless otherwise provided for by federal law. </p>
                                    <p>8. The consent may be withdrawn by me at any time on the basis of my written application.</p>
                                </div>
                            }


                        </div>
                    </div>
                </div>


                {/* Футер */}
                <FooterThree />

            </main>
        </>
    )
}

export default Policy;
