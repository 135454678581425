import { useState, useRef, useEffect } from "react";
import { FiMenu } from "react-icons/fi";
import Logo from "../../elements/logo/Logo";
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import useStickyHeader from "./useStickyHeader";
import { useTranslation } from 'react-i18next';
import "/node_modules/flag-icons/css/flag-icons.min.css";


const HeaderOne = ({ btnStyle, HeaderSTyle }) => {




    const [ofcanvasShow, setOffcanvasShow] = useState(false);

    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }

    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader(50);
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`
    const { clientHeight } = ref;
    const checkChange = (value) => {
        setCheck(value);
    };

    // useEffect(()=>{
    //     if (i18n.language === "ru") {
    //         classTabEn = '';
    //     }

    //     if (i18n.language === "en") {
    //         classTabEn = 'en';
    //     }
    // },[])

    const { t, i18n } = useTranslation();

    const [enActive, setEnActive] = useState(false);
    const [ruActive, setRuActive] = useState(true);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    useEffect(() => {
        if (i18n.language === "ru") {
            setEnActive(false);
            setRuActive(true);
        }

        if (i18n.language === "en") {
            setEnActive(true);
            setRuActive(false);
        }
    }, [i18n.language])


    return (
        <>
            <header ref={ref} className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}>
                <div className="container position-relative">
                    <div className="row align-items-center row--0">
                        <div className="col-lg-2 col-md-4 col-4">
                            <Logo
                                image={`${process.env.PUBLIC_URL}/images/logo/logo.png`}
                                image2={`${process.env.PUBLIC_URL}/images/logo/logo-dark.png`}
                            />
                        </div>
                        <div className="col-lg-10 col-md-8 col-8 position-static">
                            <div className="header-right">
                                <nav className="mainmenu-nav d-none d-lg-block">
                                    <Nav />
                                </nav>


                                {/* Тут будет смена языка */}

                                <button className={`language language_ru ${ruActive ? ' active' : ''}`} onClick={(event) => changeLanguage("ru")}>
                                    <div className={`fi fis fi-ru`}></div>
                                    <div>RU</div>
                                </button>
                                <button className={`language language_en ${enActive ? ' active' : ''}`} onClick={(event) => changeLanguage("en")}>
                                    <div className={`fi fis fi-us`}></div>
                                    <div>EN</div>
                                </button>

                                <div className="header-btn">
                                    <a className={`btn-default ${btnStyle}`} target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">{t("header.call")}</a>
                                </div>

                                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div className="hamberger">
                                        <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
        </>
    )
}
export default HeaderOne;