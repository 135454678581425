import React from 'react';
import { useTranslation } from 'react-i18next';

const AboutTwo = () => {

    const { t, i18n } = useTranslation();

    const data = [
        {
            icon: `${process.env.PUBLIC_URL}/images/icons/country.png`,
            title: t("about.advantages.itemOne.title"),
            subtitle: t("about.advantages.itemOne.subtitle")
        },
        {
            icon: `${process.env.PUBLIC_URL}/images/icons/team.png`,
            title: t("about.advantages.itemTwo.title"),
            subtitle: t("about.advantages.itemTwo.subtitle")
        },
        {
            icon: `${process.env.PUBLIC_URL}/images/icons/conversion.png`,
            title: t("about.advantages.itemThree.title"),
            subtitle: t("about.advantages.itemThree.subtitle")
        },
        {
            icon: `${process.env.PUBLIC_URL}/images/icons/profit.png`,
            title: t("about.advantages.itemFour.title"),
            subtitle: t("about.advantages.itemFour.subtitle")
        },
    ]

    return (
        <div className="rwt-about-area about-style-2 fl-about">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-7">
                        <div className="fl-about__content radius">
                            <h4>{t("about.TextAbout.TitleOne")}</h4>
                            <p>{t("about.TextAbout.AbstractOne")}</p>
                            <h4>{t("about.TextAbout.TitleTwo")}</h4>
                            <p>{t("about.TextAbout.AbstractTwo")}</p>
                            <p>{t("about.TextAbout.AbstractThree")}</p>
                        </div>
                    </div>

                    <div className="col-lg-5 mt_md--30 mt_sm--30">
                        <div className="row fl-about__advantages">
                            {data.map((el, i) => (
                                <div className="col-12 d-flex fl-about__item">
                                    <div className="fl-about__icon">
                                        <img src={el.icon} alt="" />
                                    </div>
                                    <div className="fl-about__text">
                                        <div>{el.title}</div>
                                        <div>{el.subtitle}</div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutTwo;
