import React from 'react';
import { Link } from "react-router-dom";

function MessengersPanel() {

    return (
        <div className="messenger-panel">
            <div className="messenger-panel__callback"><Link to={{ pathname: "https://vk.com/flamingo.expert" }} target="_blank"></Link></div>
            <div className="messenger-panel__whatsapp"><Link to={{ pathname: "https://api.whatsapp.com/send?phone=79282962128" }} target="_blank"></Link></div>
            <div className="messenger-panel__telegramm"><Link to={{ pathname: "https://t.me/Flamingo_expert" }} target="_blank"></Link></div>
        </div>
    )
}
export default MessengersPanel;
