import React from 'react';
import { useTranslation } from 'react-i18next';

import { FiPhone, FiMail, FiMapPin } from "react-icons/fi";

const Contact = () => {

    const { t, i18n } = useTranslation();

    return (
        <div className="row">
            <div className="col-12">
                <div className="row">
                    <div className="col-sm-6 col-12">
                        <div className="rn-address">
                            <div className="icon">
                                <FiPhone />
                            </div>
                            <div className="inner">
                                <h4 className="title">{t("contacts.phone")}</h4>
                                <p><a href="">8 (863) 296-21-28</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="rn-address">
                            <div className="icon">
                                <FiMail />
                            </div>
                            <div className="inner">
                                <h4 className="title">Email:</h4>
                                <p><a href="">info@flamingo.expert</a></p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="col-12">
                <div className="row">
                    <div className="col-12">
                        <div className="rn-address">
                            <div className="icon">
                                <FiMapPin />
                            </div>
                            <div className="inner">
                                <h4 className="title">{t("contacts.adress")}</h4>
                                <p>{t("contacts.adressRussia")}</p>
                                <p>{t("contacts.adressKazakhstan")}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Contact;