import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

// Иконки
import { SiVk } from "react-icons/si";
import { ImTelegram, ImWhatsapp } from "react-icons/im";
import Tenchat from "../../assets/images/icons/tenchat.png"
import Dzen from "../../assets/images/icons/dzen.png"
import LogoFlamingo from "../../assets/images/icons/flamingo_logo.svg"



const CopyrightTwo = () => {

    const { t, i18n } = useTranslation();

    return (
        <div className="copyright-area copyright-style-one variation-two">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-4 col-lg-7 col-md-6 col-sm-12 col-12">
                        <div className="copyright-left">
                            <ul className="ft-menu link-hover">
                                <li><Link to={`${process.env.PUBLIC_URL + "/policy"}`}>{t("footer.policy")}</Link></li>
                                <li><Link to={`${process.env.PUBLIC_URL + "/requisites"}`}>{t("footer.requisites")}</Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-5 col-md-6 mt_sm--20">
                        <div className="copyright-center text-center">
                            <ul className="social-icon social-default color-lessdark justify-content-center">
                                <li><Link to={{ pathname: "https://api.whatsapp.com/send?phone=79282962128" }} target="_blank"><ImWhatsapp /></Link></li>
                                <li><Link to={{ pathname: "https://t.me/Flamingo_expert" }} target="_blank"><ImTelegram /></Link></li>
                                <li><Link to={{ pathname: "https://vk.com/flamingo.expert" }} target="_blank"><SiVk /></Link></li>
                                <li><Link to={{ pathname: "https://dzen.ru/id/6478a2b3d1ad8e779af33fdc" }} target="_blank"><img src={Dzen} /></Link></li>
                                <li><Link to={{ pathname: "https://tenchat.ru/1211925" }} target="_blank"><img src={Tenchat} /></Link></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-12 mt_lg--20 mt_md--20 mt_sm--20 d-flex justify-content-center justify-content-xl-end">
                        <div className="copyright-right text-center text-lg-right">
                            <p className="copyright-text">
                                <div><img src={LogoFlamingo} /></div>
                                <div>Flamingo.Expert 2019 - {new Date().getFullYear()} || {t("footer.watermark")}</div>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CopyrightTwo;