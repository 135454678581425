import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack'
import axios from 'axios'
import InputMask from 'react-input-mask';

import FileUpload from "./FileUpload";
import { useEffect } from 'react';

// Вывод результата, при успешной отправки формы
const Result = () => {
    return (
        <div className="success-message">
            <div className='success-message__block'>
                <div className='success-message__img'><img src="./images/contact/done.png" /></div>
                <div className='success-message__title'>Заявка отправлена!</div>
                <div className='success-message__subtitle'>Мы рассмотрим вашу заявку<br />и свяжемся с вами в ближайшее время!</div>
            </div >
        </div>
    )
}

function ContactForm({ props, formStyle }) {

    // Показ результата после отправки формы
    const [result, showresult] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    // Смена языка
    const { t, i18n } = useTranslation();
    // Уведомления
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    // Объект с данными с формы
    const [form, setForm] = useState({
        name: '',
        phone: '',
        type: 'Разработка',
        message: '',
        files: '',
    })

    // Сбор данных с формы
    const DataCollection = (e) => {
        setForm(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    };

    // Отправка загруженного файла в переменную
    const updateUploadedFiles = (files) => {
        setForm(prevState => ({
            ...prevState,
            'files': files[0]
        }));
        setShowPreview(true);
    }

    // Проверка данных
    const InvalidForm = (authFiles) => {

        let ValidFiles = 0;
        let request = {
            valid: false,
            textMessage: '',
        }

        // Проверка полей
        if ((form.name == '' || form.phone == '' || form.type == '' || form.message == '') || form.files != '') {

            authFiles.map((el, i) => { if (el != form.files.type) { ValidFiles = ValidFiles + 1 } })

            if ((form.name == '' || form.phone == '' || form.type == '' || form.message == '') && ValidFiles > 3) {
                request.textMessage = 'Есть незаполненные поля, а так же файл не был загружен. Недопустимое разрешение файла';
                request.valid = false;
            }
            else if (ValidFiles == 3) {
                request.textMessage = 'Файл не загружен. Недопустимое разрешение файла';
                request.valid = false;
            } else if (ValidFiles < 3) {
                request.textMessage = 'Заявка успешно отправлена';
                request.valid = true;
            } else {
                request.textMessage = 'Есть незаполненные поля';
                request.valid = false;
            }
        }
        else {
            request.valid = true;
            request.textMessage = 'Заявка успешно отправлена';
        }

        ValidFiles = 0;

        return request;
    };

    // Отправка на почту
    const sendEmail = (e) => {

        e.preventDefault()

        // Узел, куда отправлять данные
        const url = window.location.protocol + '//' + window.location.host + '/php/form.php';

        // Запускаем функцию проверки данных с формы
        const valid = InvalidForm(
            [
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/x-zip-compressed'
            ]
        );

        if (valid.valid) {
            console.log(document.querySelector('input[name="phone"]').value)
            axios
                .post(url, form, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(function (body) {

                    if (body.status === 200) {
                        enqueueSnackbar(valid.textMessage, {
                            autoHideDuration: 3000,
                            variant: 'success',
                        })

                        document.querySelector('input[name="name"]').value = '';
                        document.querySelector('input[name="phone"]').value = '';
                        document.querySelector('textarea[name="message"]').value = '';

                        console.log(document.querySelector('input[name="phone"]').value)

                        showresult(true);

                        setShowPreview(false);
                    }

                })
                .catch(function (error) {

                    enqueueSnackbar('Что-то пошло не так. Заявка не отправлена.', {
                        autoHideDuration: 3000,
                        variant: 'error',
                    })

                    document.querySelector('input[name="name"]').value = '';
                    document.querySelector('input[name="phone"]').value = '';
                    document.querySelector('textarea[name="message"]').value = '';

                    setShowPreview(false);

                });
        } else {
            enqueueSnackbar(valid.textMessage, {
                autoHideDuration: 3000,
                variant: 'error',
            })
        }
    };

    return (
        <form className={`${formStyle}`} enctype="multipart/form-data" action="" onSubmit={sendEmail}>

            <h4>{t("contacts.form.title")}</h4>
            <div className="row">
                <div className="col-sm-6 col-12">
                    <div className="form-group">
                        <input
                            type="text"
                            name="name"
                            placeholder={t("contacts.form.name")}
                            required
                            onChange={e => DataCollection(e)}
                        />
                    </div>
                </div>
                <div className="col-sm-6 col-12">
                    <div className="form-group">
                        <InputMask
                            required
                            type="text"
                            name="phone"
                            mask="+7 (999) 999-99-99"
                            placeholder="+7 (___)___-__-__"
                            onChange={e => DataCollection(e)}>
                        </InputMask>
                    </div>
                </div>
            </div>

            <h4>{t("contacts.form.titleTwo")}</h4>
            <div className="fl-radio">
                <div class="fl-radio_button">
                    <input
                        type="radio"
                        name="type"
                        value={'Разработка'}
                        defaultChecked={true}
                        id="radio-1"
                        onChange={e => DataCollection(e)}
                    />
                    <label for="radio-1">{t("contacts.form.tabOne")}</label>
                </div>

                <div class="fl-radio_button">
                    <input
                        type="radio"
                        name="type"
                        value={'Маркетинг'}
                        id="radio-2"
                        onChange={e => DataCollection(e)}
                    />
                    <label for="radio-2">{t("contacts.form.tabTwo")}</label>
                </div>
            </div>

            <div className="form-group">
                <textarea
                    name="message"
                    placeholder={t("contacts.form.description")}
                    required
                    onChange={e => DataCollection(e)}
                >
                </textarea>

            </div>

            <div className="form-group file-upload">
                <FileUpload
                    accept=".pdf, .docx, .zip"
                    label=""
                    updateFilesCb={updateUploadedFiles}
                    showPreview={showPreview}
                />
            </div>

            <div className="mt--10 mb--20">{t("contacts.form.textPolicy")} <a href="">{t("contacts.form.linkPolicy")}</a></div>

            <div className="form-group">
                <button className="btn-default btn-large">{t("contacts.form.button")}</button>
            </div>

            {/* Блок с выводом результата */}
            {result ? <Result /> : null}

        </form>

    )
}
export default ContactForm;
