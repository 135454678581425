import React from 'react';
import { useTranslation } from 'react-i18next';

// Иконки
import { AiFillHtml5 } from "react-icons/ai";
import { IoLogoCss3, IoLogoJavascript } from "react-icons/io";
import { SiPhp } from "react-icons/si";
import { BiLogoJquery, BiLogoBootstrap } from "react-icons/bi";
import { FaLess, FaReact, FaGulp } from "react-icons/fa";
import { BsFiletypeScss, BsFiletypeJsx } from "react-icons/bs";

const dataTechology = [
    {
        id: 1,
        name: "HTML",
        icon: <AiFillHtml5 />
    },
    {
        id: 2,
        name: "CSS",
        icon: <IoLogoCss3 />
    },
    {
        id: 3,
        name: "JavaScript",
        icon: <IoLogoJavascript />
    },
    {
        id: 4,
        name: "PHP",
        icon: <SiPhp />
    },
    {
        id: 5,
        name: "Jquery",
        icon: <BiLogoJquery />
    },
    {
        id: 6,
        name: "Bootstrap",
        icon: <BiLogoBootstrap />
    },
    {
        id: 7,
        name: "LESS",
        icon: <FaLess />
    },
    {
        id: 8,
        name: "SCSS",
        icon: <BsFiletypeScss />
    },
    {
        id: 9,
        name: "React",
        icon: <FaReact />
    },
    {
        id: 10,
        name: "JSX",
        icon: <BsFiletypeJsx />
    },
    {
        id: 11,
        name: "Gulp",
        icon: <FaGulp />
    },

]

const TechologyPortfolio = ({ data }) => {

    const { t, i18n } = useTranslation();

    return (
        <div className="portfolio-details__techology">
            <div className="portfolio-details__subtitle">{t("cases.caseDevelop.technology")}</div>
            <div className="row">
                {data.map((el, index) => (
                    dataTechology.map((tech, i) => (
                        el.id === tech.id &&
                        <div className="col-sm-3 col-4">
                            <div className="portfolio-details__techology-item">
                                <div>{tech.icon}</div>
                                <div>{tech.name}</div>
                            </div>
                        </div>
                    ))
                ))}
            </div>
        </div>
    )
}

export default TechologyPortfolio;
