import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import emailjs from 'emailjs-com';
import { Link } from "react-router-dom";
import SectionTitle from '../sectionTitle/SectionTitle';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}

function FormAudit({ props, formStyle }) {

    const { t, i18n } = useTranslation();

    const [result, showresult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_p4x3hv8',
                'template_jgfr42f',
                e.target,
                'user_jrfTH2e0Ely35ZCVFdT9S'
            )
            .then((result) => {
                console.log(result.text);
            },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <div className="fl-audit bg-color-blackest radius mt--60">
            <div className="row position-relative align-items-center">

                <div className="col-lg-6 col-12 order-2 order-lg-1">
                    <SectionTitle
                        textAlign="text-left"
                        radiusRounded=""
                        subtitle={t("audit.subtitle")}
                        title={t("audit.title")}
                        description={t("audit.description")}
                    />

                    <div className="read-more-btn mt--30">
                        <Link className="btn-default btn-large" to="#">{t("audit.button")}</Link>
                    </div>
                </div>

                <div className="col-xl-3 col-lg-6 col-12 order-1 order-lg-2">
                    <div className="fl-audit__image">
                        <img src="./images/contact/back_01.png" />
                    </div>
                </div>

                <div className="col-3 d-none d-xl-block order-lg-3">
                    <div className="fl-audit__content">
                        <div>
                            <p>{t("audit.add.addBlockOne.subtitle")}</p>
                            <div>{t("audit.add.addBlockOne.title")}</div>
                        </div>
                        <div>
                            <p>{t("audit.add.addBlockTwo.subtitle")}</p>
                            <div>{t("audit.add.addBlockTwo.title")}</div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}
export default FormAudit;
