import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const TabPortfolio = ({ data, addClass }) => {

    const { t, i18n } = useTranslation();

    let classTabEn;

    if (i18n.language === "ru") {
        classTabEn = '';
    }

    if (i18n.language === "en") {
        classTabEn = 'en';
    }

    // Скролл мобильной версии кейса
    function ScrollMobilePortfolio(event) {
        event.target.classList.add('active');
    }

    // Установка отступа между табом и скроллом, если таб панель имеет скролл
    useEffect(() => {
        const tabs = [...document.querySelectorAll('.tab-button')];
        tabs.forEach((el, i) => {
            if (el.scrollWidth > el.clientWidth) {
                el.style.paddingBottom = '24px';
            }
        })
    }, [])

    // Функция автоматической прокрутки скролла при нажатии на следующий таб
    function autoScrolling(event) {

        const currentTab = event.currentTarget;
        const parentTab = currentTab.closest('.tab-button');
        const allTabs = [...parentTab.querySelectorAll('.react-tabs__tab')];
        const keyCurrentTab = Number(currentTab.getAttribute('data-key'));

        let tabActive;

        allTabs.map((tab, i) => {
            if (tab.classList.contains('react-tabs__tab--selected')) {
                tabActive = Number(tab.getAttribute('data-key'));
            }
        });

        // Если след.таб для нажатия лежит после активного, то скролл вправо
        if (keyCurrentTab > tabActive) {
            parentTab.scrollLeft += currentTab.offsetWidth - 20;
        }

        // Если след.таб для нажатия лежит перед активным, то скролл влево
        if (keyCurrentTab < tabActive) {
            parentTab.scrollLeft -= currentTab.offsetWidth - 20;
        }
    }

    return (
        <div className={"portfolio-details__tabs " + addClass}>
            <div className="row">
                <div className="col-lg-12">
                    <Tabs>
                        <div className="row row--30 align-items-center">
                            <div className="col-lg-12 mt_md--40 mt_sm--40">
                                <div className="rn-default-tab style-two">
                                    <div className="tab-button-panel">
                                        <TabList className="tab-button">
                                            {data.map((tab, i) => (
                                                <Tab onClick={event => autoScrolling(event)} data-key={i + 1}>
                                                    <div className="rn-tab-button">
                                                        <button>{tab.nameTab}</button>
                                                    </div>
                                                </Tab>
                                            ))}
                                        </TabList>
                                    </div>

                                    <div className="tab-content-panel">
                                        {data.map((tab, i) => (
                                            <TabPanel>
                                                {tab.img.length === 1 && !addClass &&
                                                    tab.img.map((img, i) => (
                                                        <div className="portfolio-details__tab">
                                                            <img src={img} alt="" />
                                                        </div>
                                                    ))
                                                }

                                                {tab.img.length === 1 && addClass &&
                                                    tab.img.map((img, i) => (
                                                        <div className={"portfolio-details__tab-img " + classTabEn} onClick={event => ScrollMobilePortfolio(event)}>
                                                            <div>
                                                                <div>
                                                                    <img src={img} alt="" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                                {tab.img.length > 1 &&
                                                    <div className="portfolio-details__tab portfolio-details_tab-3">
                                                        {tab.img.map((img, i) => (
                                                            <div className={"portfolio-details__tab-img " + classTabEn} onClick={event => ScrollMobilePortfolio(event)}>
                                                                <div>
                                                                    <div>
                                                                        <img src={img} alt="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                }
                                            </TabPanel>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default TabPortfolio
