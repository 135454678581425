import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import SectionTitle from '../sectionTitle/SectionTitle';
import { useTranslation } from 'react-i18next';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}

function FormSale({ props, formStyle }) {

    const { t, i18n } = useTranslation();

    const [result, showresult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_p4x3hv8',
                'template_jgfr42f',
                e.target,
                'user_jrfTH2e0Ely35ZCVFdT9S'
            )
            .then((result) => {
                console.log(result.text);
            },
                (error) => {
                    console.log(error.text);
                }
            );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (

        <div className="fl-sale bg-color-blackest radius mt--180">
            <div className="row position-relative align-items-center flex-column flex-lg-row">


                <div className="col-xl-8 col-lg-7 col-12 order-lg-1 order-2">
                    <div className="fl-sale__content">
                        <SectionTitle
                            textAlign="text-left"
                            radiusRounded=""
                            subtitle={t("services.sale.subtitle")}
                            title={t("services.sale.title")}
                            description={t("services.sale.decription")}
                        />

                        <div><button className="btn-default btn-large">{t("services.sale.button")}</button></div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 order-lg-2 order-1">
                    <div className="fl-sale__image">
                        <img src="./images/contact/sale_1.png" />
                    </div>
                </div>

            </div>
        </div>


    )
}
export default FormSale;
